import { ElementType } from "react";

type FloatingBannerProps = {
  icon: ElementType;
  lg_text: string;
  sm_text: string;
};

export const FloatingBanner = ({
  icon: Icon,
  lg_text,
  sm_text,
}: FloatingBannerProps) => (
  <div className="mx-auto px-2 sm:px-6 lg:px-8">
    <div className="rounded-lg bg-primary-500 p-2 shadow-lg sm:p-3">
      <div className="flex flex-wrap items-center justify-between">
        <div className="flex w-0 flex-1 items-center">
          <span className="flex rounded-lg bg-primary-700 p-2">
            <Icon className="h-6 w-6 text-white" aria-hidden="true" />
          </span>
          <p className="ml-3 truncate font-medium text-white">
            <span className="md:hidden">{sm_text}</span>
            <span className="hidden md:inline">{lg_text}</span>
          </p>
        </div>
      </div>
    </div>
  </div>
);
