import LanguageSelector from "@crafthunt-ui/LanguageSelector/LanguageSelector";
import { TranslatedSlugDef } from "@translations/types/translation.types";
import Logo from "assets/crafthunt.svg";
import clsx from "clsx";
import { STORYBLOK_COMPANY_PAGES } from "constants/routes.constants";
import { useTranslation } from "next-i18next";
import Link from "next/link";
import { useRouter } from "next/router";
import { FaFacebook, FaInstagram, FaLinkedin, FaTwitter } from "react-icons/fa";
import {
  StoryblokNavButtonDef,
  StoryblokSettingsDef,
} from "types/storyblok.types";
import { removeEmpty } from "utils/helpers";
import { getTranslatedSlug } from "utils/router.helper";

type FooterProps = {
  settings: StoryblokSettingsDef;
  translatedSlugs?: TranslatedSlugDef[];
  isCompanyPage?: boolean;
};

const Footer = ({ settings, translatedSlugs, isCompanyPage }: FooterProps) => {
  const { t } = useTranslation();
  const router = useRouter();
  const thisYear = new Date().getFullYear();
  const viewingCompanyPage =
    isCompanyPage ??
    STORYBLOK_COMPANY_PAGES.some((cp) => router.asPath.includes(cp));

  const filteredQueriesForLink = removeEmpty({
    ...router.query,
    slug: undefined,
    step: undefined,
    email: undefined,
    phone: undefined,
    id: undefined,
    use: undefined,
    channelId: undefined,
    page: undefined,
    from: undefined,
  });

  const filterLinks = (links: StoryblokNavButtonDef[]) => {
    if (!viewingCompanyPage) {
      return links;
    }
    return links.filter((item) => {
      const itemUrl =
        item.url.cached_url || item.url.url || item.url.story?.full_slug;
      return (
        !itemUrl.includes("/blog/") &&
        !STORYBLOK_COMPANY_PAGES.some((cp) => itemUrl.includes(cp))
      );
    });
  };

  const getIcon = (icon: string) => {
    switch (icon) {
      case "facebook":
      case "facebook-square":
        return <FaFacebook className="h-6 w-6" />;
      case "instagram":
        return <FaInstagram className="h-6 w-6" />;
      case "linkedin":
        return <FaLinkedin className="h-6 w-6" />;
      case "twitter":
        return <FaTwitter className="h-6 w-6" />;
      default:
        return null;
    }
  };

  return (
    <footer className="bg-gray-900" aria-labelledby="footer-heading">
      <h2 id="footer-heading" className="sr-only">
        Footer
      </h2>
      <div
        className={clsx(
          "mx-auto max-w-7xl px-6 pb-8 pt-16 sm:pt-16 lg:px-8 lg:pt-24"
        )}
      >
        <div className="md:grid md:grid-cols-2 lg:grid-cols-4 lg:gap-8">
          <div className="space-y-8 lg:mr-10">
            <div className="flex justify-between">
              <Logo className="h-8 w-auto sm:h-10" />
              {viewingCompanyPage && (
                <div className="md:hidden">
                  <LanguageSelector
                    translatedSlugs={translatedSlugs}
                    compressed
                  />
                </div>
              )}
            </div>
            <p className="text-sm leading-6 text-gray-300">
              {t("global-short-crafthunt-claim")}
            </p>
            <p className="text-sm text-gray-300">
              <span className="text-gray-400">{t("email")}: </span>
              <a
                className="font-medium hover:text-primary-700"
                href={`mailto:${settings?.nav_email}`}
              >
                {settings?.nav_email}
              </a>
              <br></br>
              <span className="text-gray-400">{t("phone")}: </span>
              <a
                className="font-medium text-primary-500 hover:text-primary-700"
                href={`tel:${settings?.nav_phone}`}
              >
                {settings?.nav_phone}
              </a>
            </p>
            <div className="flex space-x-6">
              {settings?.footer_social?.map((item) => (
                <a
                  key={item._uid}
                  href={getTranslatedSlug(item.link)}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-gray-400 hover:text-gray-500"
                >
                  <span className="sr-only">{item.name}</span>
                  {getIcon(item.icon)}
                </a>
              ))}
            </div>
          </div>
          {!viewingCompanyPage && (
            <div className="mt-10 space-y-8 md:mt-0">
              <h3 className="text-sm font-semibold leading-6 text-white">
                {t("footer-for-candidates")}
              </h3>
              <ul role="list" className="mt-6 space-y-4">
                {settings?.candidate_links.map((item) => (
                  <li key={item._uid}>
                    <Link
                      href={{
                        pathname: getTranslatedSlug(item),
                        query: filteredQueriesForLink,
                      }}
                      className="text-sm leading-6 text-gray-300 hover:text-white"
                    >
                      {item.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          )}
          <div className="mt-10 space-y-8 md:mt-10 lg:mt-0">
            <h3 className="text-sm font-semibold leading-6 text-white">
              {t("footer-for-employers")}
            </h3>
            <ul role="list" className="mt-6 space-y-4">
              {filterLinks(settings?.company_links)?.map((item) => (
                <li key={item._uid}>
                  <Link
                    href={{
                      pathname: getTranslatedSlug(item),
                      query: filteredQueriesForLink,
                    }}
                    className="text-sm leading-6 text-gray-300 hover:text-white"
                  >
                    {item.name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
          <div className="mt-10 space-y-8 md:mt-10 lg:mt-0">
            <h3 className="text-sm font-semibold leading-6 text-white">
              {t("footer-about-us")}
            </h3>
            <ul role="list" className="mt-6 space-y-4">
              {settings?.footer_links?.map((item) => (
                <li key={item._uid}>
                  <Link
                    href={{
                      pathname: getTranslatedSlug(item),
                      query: filteredQueriesForLink,
                    }}
                    className="text-sm leading-6 text-gray-300 hover:text-white"
                  >
                    {item.name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="mt-16 border-t border-white/10 pt-8 sm:mt-20 lg:mt-24">
          <div
            className="-mb-6 columns-2 sm:flex sm:justify-start sm:space-x-12"
            aria-label="Footer"
          >
            <div className="pb-6 text-sm leading-6 text-gray-500">
              &copy; {thisYear} Crafthunt GmbH. All rights reserved.
            </div>
            {settings?.legal_links?.map((item) => (
              <div key={item._uid} className="pb-6">
                <Link
                  href={{
                    pathname: getTranslatedSlug(item),
                    query: filteredQueriesForLink,
                  }}
                  className="text-sm leading-6 text-gray-500 hover:text-white"
                >
                  {item.name}
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
